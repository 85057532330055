<template>
    <div>
      <ViewReport></ViewReport>
    </div>
  </template>
  
  <script>
  import ViewReport from '@/components/report/ViewReport.vue';
  export default {
          components: {
              ViewReport
          }
      
  }
  </script>
  
  <style>
  
  </style>